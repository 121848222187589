import {getLibUrl} from "o365.modules.helpers.js";

class DiagramEditor extends EventTarget
{
    #EVENTS = new Set([
            'onStopEditing',
            'onSaved',
            'onExported'
        ]);

    config = null;
    drawDomain = window.location.origin + '/nt/drawio/root';
    ui = 'min';
    xml = null;
    format = 'xml';
    libraries = true;
    frameStyle = 'border:0;width:100%;height:100%;';

    constructor (config, ui, done, initialized, customconfig) {
        super();
        this.config = config ?? this.config;
        this.ui = ui ?? this.ui;
        this.done = done ?? this.done;
        this.initialized = initialized ?? this.initialized;
        this.customconfig = customconfig ?? this.customconfig;
        
        this.container = this.customconfig?.container ?? document.body;
        this.autosave = this.customconfig?.autosave ?? 1;
        this.saveAndExit = this.customconfig?.saveAndExit ?? '1';
        this.spin = this.customconfig?.spin ?? '0';
        this.noExitBtn = this.customconfig?.noExitBtn ?? '0';
        this.preview = this.customconfig?.preview ?? false;
        this.nav = this.customconfig?.nav ?? false;

        this.handleMessageEvent = this.handleMessageEvent.bind(this);
    }

    addEventListener(event, callback) {
        if (this.#EVENTS.has(event) === false) {
            throw Error(`Failed to register event listener. Event '${event}' does not exist on DiagramEditor`);
        }

        super.addEventListener(event, callback);
    }

    removeEventListener(event, callback) {
        if (this.#EVENTS.has(event) === false) {
            throw Error(`Failed to remove event listener. Event '${event}' does not exist on DiagramEditor`);
        }

        super.removeEventListener(event, callback);
    }

    handleMessageEvent(evt)
    {
        if (this.frame != null && evt.source == this.frame.contentWindow && evt.data.length > 0)
        {
            try
            {
                var msg = JSON.parse(evt.data);

                if (msg != null)
                {
                    this.handleMessage(msg);
                }
            }
            catch (e)
            {
                console.error(e);
            }
        }
    };

    handleMessage(msg){
        if (msg.event == 'configure')
        {
            this.configureEditor();
        }
        else if (msg.event == 'init')
        {
            this.initializeEditor();
        }
        else if (msg.event == 'autosave')
        {
            this.save(msg.xml, true, this.startElement);
        }
        else if (msg.event == 'export')
        {
            this.setElementData(this.startElement, msg.data);
            if(this.saveAndExit === '1'){
                this.stopEditing();    
            }
            // this.xml = null;
        }
        else if (msg.event == 'save')
        {
            this.save(msg.xml, false, this.startElement);
            this.xml = msg.xml;

            if (msg.exit)
            {
                msg.event = 'exit';
            }
            else
            {
                this.setStatus('allChangesSaved', false);
            }
        }

        if (msg.event == 'exit')
        {
            if (this.format != 'xml')
            {
                if (this.xml != null)
                {
                        this.postMessage({action: 'export', format: this.format,
                    xml: this.xml, spinKey: 'export'});
                }
                else
                {
                    this.stopEditing(msg);
                }
            }
            else
            {
                if (msg.modified == null || msg.modified)
                {
                        this.save(msg.xml, false, this.startElement);
                }

                this.stopEditing(msg);
            }
        }
    };

    editElement(elt, config, ui, done, customconfig){
        if (!elt.diagramEditorStarting)
        {
            elt.diagramEditorStarting = true;

            return new DiagramEditor(config, ui, done, function()
            {
                delete elt.diagramEditorStarting;
            }, customconfig).editElement(elt);
        }
    };

    editElement(elem){
        var src = this.getElementData(elem);
        this.startElement = elem;
        var fmt = this.format;

        if (src.substring(0, 15) === 'data:image/png;')
        {
            fmt = 'xmlpng';
        }
        else if (src.substring(0, 19) === 'data:image/svg+xml;' ||
            elem.nodeName.toLowerCase() == 'svg')
        {
            fmt = 'xmlsvg';
        }

        this.startEditing(src, fmt);

        return this;
    };

    getElementData(elem){
        var name = elem.nodeName.toLowerCase();

        return elem.getAttribute((name == 'svg') ? 'content' :
            ((name == 'img') ? 'src' : 'data'));
    };

    setElementData(elem, data){
        if(elem){
            var name = elem.nodeName.toLowerCase();
            if (name == 'svg')
            {
                elem.outerHTML = atob(data.substring(data.indexOf(',') + 1));
            }
            else
            {
                elem.setAttribute((name == 'img') ? 'src' : 'data', data);
            }
        }

        this.dispatchEvent(new CustomEvent('onExported', {detail: data}));
        
        return elem;
    };

    startEditing(data, format, title){
        if (this.frame == null)
        {
            window.addEventListener('message', this.handleMessageEvent);
            this.format = (format != null) ? format : this.format;
            this.title = (title != null) ? title : this.title;
            this.data = data;

            this.frame = this.createFrame(
                this.getFrameUrl(),
                this.getFrameStyle());
            this.container.appendChild(this.frame);
            this.setWaiting(true);
        }
        
        return this;
    };

    addPluginScript(scriptName){
        var pluginScript = document.createElement('script');
        pluginScript.setAttribute('src', '/nt/scripts/site/' + scriptName);
        this.frame.contentWindow.document.head.appendChild(pluginScript);       
        /* TODO:
            mxSettings.getPlugins().push("https://dev-nt.omega365.com/scripts/site/o365.controls.DiagramEditor.plugins.test.-686966509.js"), mxscript("https://dev-nt.omega365.com/scripts/site/o365.controls.DiagramEditor.plugins.test.-686966509.js")
         */ 
    };
    
    setWaiting(waiting){
        if (this.startElement != null)
        {
            var elt = this.startElement;
            var name = elt.nodeName.toLowerCase();

            if (name == 'svg' || name == 'object')
            {
                elt = elt.parentNode;
            }

            if (elt != null)
            {
                if (waiting)
                {
                    this.frame.style.pointerEvents = 'none';
                    this.previousCursor = elt.style.cursor;
                    elt.style.cursor = 'wait';
                }
                else
                {
                    elt.style.cursor = this.previousCursor;
                    this.frame.style.pointerEvents = '';
                }
            }
        }
    };

    setActive(active){
        if (active)
        {
            this.previousOverflow = this.container.style.overflow;
            this.container.style.overflow = 'hidden';
        }
        else
        {
            this.container.style.overflow = this.previousOverflow;
        }
    };

    stopEditing(){
        if (this.frame != null)
        {
            window.removeEventListener('message', this.handleMessageEvent);
            this.container.removeChild(this.frame);
            this.setActive(false);
            this.frame = null;
        }

        this.dispatchEvent(new CustomEvent('onStopEditing'));
    };


    postMessage(msg){
        if (this.frame != null)
        {
            this.frame.contentWindow.postMessage(JSON.stringify(msg), '*');
        }
    };

    
    getData(){
        return this.data;
    };

    getTitle(){
        return this.title;
    };

    getFrameStyle(){
        return this.frameStyle;
    };

    getFrameUrl(){
        var url =  `${this.drawDomain}?t=1&embed=1&proto=json&spin=${this.spin}`;

        if (this.noExitBtn === '1') {
            url += '&noExitBtn=1';
        }

        if (this.ui != null)
        {
            url += '&ui=' + this.ui;
        }

        if (this.libraries != null)
        {
            url += '&libraries=1';
        }

        if (this.config != null)
        {
            url += '&configure=1';
        }

        if (this.preview)
        {
            //url += '&chrome=0';
            url += '&lightbox=1';            
        }

        if (this.nav)
        {
            url += '&nav=1';            
        }
        return url;
    };

    createFrame(url, style){
        var frame = document.createElement('iframe');
        frame.setAttribute('frameborder', '0');
        frame.setAttribute('style', style);
        frame.setAttribute('src', url);
        frame.onload = () => {
            let window = document.getElementsByTagName('iframe')[0].contentWindow;

            let pluginPath = getLibUrl("o365.DrawIo.Plugin.js");
            //window.mxscript(pluginPath);

            //window.mxscript(getLibUrl("o365.DrawIo.PersonsGenerate.Plugin.js"));
        };
        return frame;
    };

    setStatus(messageKey, modified){
        this.postMessage({action: 'status', messageKey: messageKey, modified: modified});
    };

    configureEditor(){
        this.postMessage({action: 'configure', config: this.config});
    };


    /**
     * Posts load message to editor.
     */
    initializeEditor(){
        this.postMessage({action: 'load', autosave: this.autosave, saveAndExit: this.saveAndExit,
            modified: 'unsavedChanges', xml: this.getData(),
            title: this.getTitle()});
        this.setWaiting(false);
        this.setActive(true);
        this.initialized();
    };

    
    /**
     * Saves the given data.
     */
    save(data, draft, elt)
    {
        this.done(data, draft, elt);
    };


    
    /**
     * Invoked after save.
     */
    done(data, draft, elt)
    {
        this.dispatchEvent(new CustomEvent('onSaved', {detail: data}));
    };

    

    /**
     * Invoked after the editor has sent the init message.
     */
    initialized()
    {
        // hook for subclassers
    };
};




export default DiagramEditor;